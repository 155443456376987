import Vue from "vue";

/*TITLE*/
document.title = "Cronos IV | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Cronos IV";
Vue.prototype.$subtitle = "Viviendas, áticos y bajos con jardín";

/*INTRO*/
Vue.prototype.$promoter = "Cronos IV";
Vue.prototype.$introSubtitle = "Viviendas, áticos y bajos con jardín";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-forest-arce--20230112050149.jpg";
Vue.prototype.$image_kitchen = "cocina-forest-arce-blanco-nubol-compact-plomo--20230112050159.jpg";
Vue.prototype.$image_bath1 = "banyo1-forest-arceurban-caliza-naturecubik-urban-caliza--20230112050120.jpg";
Vue.prototype.$image_bath2 = "banyo2-forest-arceurban-caliza-naturecubik-urban-caliza--20230112050126.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-forest-arce--20230112050149.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-forest-arce-blanco-nubol-compact-plomo--20230112050159.jpg",
  },
  {
    src: "cocina-forest-arce-blanco-nubol-compact-ceniza--20230112050106.jpg",
  },
  {
    src: "cocina-forest-arce-blanco-nubol-compact-arena--20230112050112.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo1-forest-arceurban-caliza-naturecubik-urban-caliza--20230112050120.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo2-forest-arceurban-caliza-naturecubik-urban-caliza--20230112050126.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://kuula.co/share/7vK4G/collection/7lkgn";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/YZy3YwNCiKU";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20de%20cronos%204%2028037%20madrid",
    text: "Calle de Cronos, 4. 28037 Madrid",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20de%20cronos%204%2028037%20madrid",
    text: "Calle de Cronos, 4. 28037 Madrid",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:914448431",
    text: "914448431",
  },
  {
    icon: "mdi-email",
    link: "mailto:clientes@cci10.com",
    text: "clientes@cci10.com",
  },
];
